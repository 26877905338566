import React from 'react'
import moment from 'moment'
import Layout from '../components/layout'

class Clock extends React.Component {

    constructor(props) {
        super(props)
        const weddingDay = moment('04/07/2015', 'DD/MM/YYYY')
        const weddingData = moment.duration(weddingDay.diff(moment()))
        const years = Math.abs(weddingData.years())
        const months = Math.abs(weddingData.months())
        const days = Math.abs(weddingData.days())
        const hours = Math.abs(weddingData.hours())
        const minutes = Math.abs(weddingData.minutes())
        const seconds = Math.abs(weddingData.seconds())
        this.state = {
            weddingDay,
            weddingData,
            years,
            months,
            days,
            hours,
            minutes,
            seconds,
        }
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        )
    }

    componentWillUnmount() {
        clearInterval(this.timerID)
    }

    tick() {
        const weddingData = moment.duration(this.state.weddingDay.diff(moment()))
        const years = Math.abs(weddingData.years())
        const months = Math.abs(weddingData.months())
        const days = Math.abs(weddingData.days())
        const hours = Math.abs(weddingData.hours())
        const minutes = Math.abs(weddingData.minutes())
        const seconds = Math.abs(weddingData.seconds())
        this.setState({
            weddingData,
            years,
            months,
            days,
            hours,
            minutes,
            seconds,
        })
    }

    render() {
        return (
            <Layout >
                <div className='counter'>

                <div >
                    <h1> {moment.duration(this.state.years, 'years').humanize()} </h1>
                    <h2> {this.state.months ? moment.duration(this.state.months, 'months').humanize() : ''} </h2>
                    <h3> {this.state.days ? moment.duration(this.state.days, 'days').humanize() : ''} </h3>
                    <h4> {this.state.hours ? moment.duration(this.state.hours, 'hours').humanize() : ''} </h4>
                    <h5> {this.state.minutes ? moment.duration(this.state.minutes, 'minutes').humanize() : ''} </h5>
                    <h6> {this.state.seconds ? moment.duration(this.state.seconds, 'seconds').asSeconds() + ' seconds' : ''} </h6>
                </div>

                </div>
            </Layout>
        )
    }
}
export default () => <Clock />